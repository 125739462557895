import { useEffect, useMemo, useState } from 'react';
import type { JSX } from 'react';

import { useLocation } from 'react-router';

import { Box } from '@change-corgi/design-system/layout';

import { useCurrentRouteConfig } from 'src/app/routes';

function SsrWarningBannerInner() {
	const location = useLocation();
	const [hasLocationChanged, setHasLocationChanged] = useState<boolean | undefined>(undefined);

	// we should not display the banner after navigating to another route
	useEffect(() => setHasLocationChanged((prevValue) => prevValue !== undefined), [location]);

	const routeConfig = useCurrentRouteConfig();
	// checking if the current route has SSR enabled
	const ssrRoute = useMemo(() => routeConfig?.config?.ssr && routeConfig.config.ssr.enabled, [routeConfig]);

	if (hasLocationChanged || !ssrRoute) return null;

	return (
		<Box
			sx={{
				position: 'fixed',
				zIndex: 1000000,
				top: 0,
				left: 0,
				width: '100vw',
				lineHeight: '24px',
				boxSizing: 'border-box',
				backgroundColor: 'rgba(128, 0, 0, 0.6)',
				pointerEvents: 'none',
				color: 'white',
				fontSize: '16px',
				textAlign: 'center',
			}}
		>
			⚠️ This SSR page is currently accessed in CSR mode ⚠️
		</Box>
	);
}

export function SsrWarningBanner({ ssr }: { ssr: boolean }): JSX.Element | null {
	if (ssr || process.env.NODE_ENV !== 'development') return null;

	// using an inner component to avoid doing route filtering in non-dev env
	return <SsrWarningBannerInner />;
}
