import { useEffect, useRef } from 'react';
import type { JSX } from 'react';

import { CacheProvider } from '@emotion/react';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

import { Optimizely } from '@change-corgi/core/react/optimizely';
import { SetPageStateSession } from '@change-corgi/core/react/pageState';
import { AppRenderInfoProvider, ClientRender } from '@change-corgi/core/react/ssr/render';
import { useUtilityContext, UtilityContextProvider } from '@change-corgi/core/react/utilityContext';
import type { Session } from '@change-corgi/core/session';
import { isSsr } from '@change-corgi/core/ssr';
import { DesignSystem } from '@change-corgi/design-system/core';
import { ModalsContainer } from '@change-corgi/design-system/modals';

import { Banner } from 'src/app/app/banner';
import { CookieWall } from 'src/app/app/cookieWall';
import { SsrWarningBanner } from 'src/app/app/dev';
import { SkipToMainContent } from 'src/app/app/layout';
import type { LoadingContext } from 'src/app/app/topLoadingBar';
import { TopLoadingBar } from 'src/app/app/topLoadingBar';
import { Head } from 'src/app/shared/components/head';
import { NewRelic } from 'src/app/shared/components/thirdParty/newRelic';
import { useAttemptLoginByFacebook } from 'src/app/shared/hooks/auth';
import { useCache } from 'src/app/shared/hooks/cache';
import type { AppCache } from 'src/app/shared/hooks/cache';
import { useCommonFcm } from 'src/app/shared/hooks/commonFcm';
import { FcmCacheProvider } from 'src/app/shared/hooks/fcm';
import { useFocusFallback } from 'src/app/shared/hooks/focus';
import { L10n, useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useNotifyProfitWell } from 'src/app/shared/hooks/profitWell';
import {
	SessionProvider,
	useCookiePrefAsync,
	usePersistCookiePrefs,
	useSessionAsync,
} from 'src/app/shared/hooks/session';
import { ShortUrlsCacheProvider } from 'src/app/shared/hooks/shortUrls';
import { useWebappTrackLoad } from 'src/app/shared/hooks/webapp';
import { isLoaded } from 'src/app/shared/utils/async';

import { AppGlobalStyle } from './GlobalStyle';
import type { AppOptions } from './types';

/*
 *
 * TODO: Re-impleent Google Tag Manager code in fe-core
 *
 * https://change.atlassian.net/browse/FE-222
 *
 */
// Prod-only static Google Tag Manager config value
const googleTagManagerContainerId = 'GTM-NMT3DV';
const googleTagManagerScript = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerContainerId}');
`;

function ThirdPartyUtilities() {
	const commonFcmState = useCommonFcm();
	const analyticsCookies = useCookiePrefAsync('analytics');
	const marketingCookies = useCookiePrefAsync('marketing');
	const { environment } = useUtilityContext();

	useAttemptLoginByFacebook();

	if (!isLoaded(commonFcmState)) return null;

	const gtmEnabled =
		environment.getEnvironment() === 'production' &&
		((isLoaded(analyticsCookies) && analyticsCookies.value) || (isLoaded(marketingCookies) && marketingCookies.value));

	return (
		<>
			<NewRelic allowed={commonFcmState.values.newRelicEnabled} />
			<ClientRender>
				{/* when no user data in SSR, we should wait for hydration to inject optimizely */}
				{/* otherwise, isOptOut: true will disable optimizely for all users even if they have approved cookies */}
				{/* we are also conditionally injecting the script here so that the optimizely state in the utility stays 'undefined' until the cookie prefs are available */}
				{isLoaded(analyticsCookies) && (
					<Optimizely
						allowed
						trackingAllowed={analyticsCookies.value}
						locationMatch={commonFcmState.values.optimizelyLocationMatch}
					/>
				)}
			</ClientRender>
			{gtmEnabled && (
				<Helmet>
					<script>{googleTagManagerScript}</script>
				</Helmet>
			)}
		</>
	);
}

export function InnerApp({
	utilities,
	history,
	context,
	loadingContext,
}: Pick<AppOptions, 'utilities' | 'history'> & {
	loadingContext?: LoadingContext;
	// used to retrieve context for prefetching within the route config
	// TODO we should find a better way to handle this
	context: { cache?: AppCache; session?: Session };
}): JSX.Element {
	const analyticsCookies = useCookiePrefAsync('analytics');
	const session = useSessionAsync();
	const locale = useLocale();
	const countryCode = useCountryCode();

	const cache = useCache();

	useEffect(() => {
		// eslint-disable-next-line no-param-reassign
		context.cache = cache;
	}, [cache, context]);
	useEffect(() => {
		// session can be updated by something like the FB autologin
		if (isLoaded(session)) {
			// eslint-disable-next-line no-param-reassign
			context.session = session.value;
		}
	}, [session, context]);

	// saves cookies prefs from localStorage to session when users logs in
	usePersistCookiePrefs();

	useNotifyProfitWell();

	useEffect(() => {
		// as toggling is done in the bootstrapping as well, we want to avoid switching it to false while loading
		if (analyticsCookies.status === 'loaded') {
			utilities.googleAnalytics.toggle(analyticsCookies.value);
		}
	}, [utilities, analyticsCookies]);

	return (
		<ModalsContainer history={history}>
			<TopLoadingBar loadingContext={loadingContext} />
			<SetPageStateSession
				session={isLoaded(session) ? session.value : undefined}
				locale={locale}
				countryCode={countryCode}
			/>
			<ThirdPartyUtilities />
			<SkipToMainContent />
			{/* must be first element in DOM */}
			<CookieWall />
			<Banner />
			<Outlet />
		</ModalsContainer>
	);
}

export function App(appOptions: AppOptions): JSX.Element | null {
	const {
		utilities,
		l10n: { locale, countryCode },
		ssrContext,
		session,
		cache,
		emotionCache,
		queryClient,
		dehydratedQueryClient,
	} = appOptions;

	useWebappTrackLoad(appOptions);

	const bodyRef = useRef(isSsr() ? null : document.getElementById('rootApp'));
	useFocusFallback(bodyRef, ({ focus, container }) =>
		focus(document.querySelector('[data-maincontent-principal]') || container, { preventScroll: true }),
	);

	// TODO split component to avoid following eslint comment
	/* eslint-disable react/jsx-max-depth */
	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary state={dehydratedQueryClient}>
				<CacheProvider value={emotionCache}>
					<DesignSystem>
						<AppRenderInfoProvider>
							<L10n locale={locale} countryCode={countryCode}>
								<SessionProvider session={session}>
									<AppGlobalStyle />
									<UtilityContextProvider utilities={utilities}>
										<FcmCacheProvider initialState={cache?.fcm}>
											<ShortUrlsCacheProvider initialState={cache?.shortUrls}>
												<Helmet
													titleTemplate="%s · Change.org"
													defaultTitle={`Change.org · ${utilities.i18n.translate('fe.pages.home.title')}`}
													bodyAttributes={{
														// necessary for focus fallback
														tabindex: '-1',
													}}
												>
													<html lang={locale} />
												</Helmet>
												<Head />
												<SsrWarningBanner ssr={!!ssrContext} />
												<Outlet />
											</ShortUrlsCacheProvider>
										</FcmCacheProvider>
									</UtilityContextProvider>
								</SessionProvider>
							</L10n>
						</AppRenderInfoProvider>
					</DesignSystem>
				</CacheProvider>
			</HydrationBoundary>
		</QueryClientProvider>
	);
	/* eslint-enable react/jsx-max-depth */
}
