import type { PrefetchableComponentType } from '@change-corgi/core/react/prefetch';
import { withPrefetchedData } from '@change-corgi/core/react/prefetch';

import { Banner } from 'src/app/app/banner';
import { DefaultLayout } from 'src/app/app/layout';
import { prefetchCommonFcm } from 'src/app/shared/hooks/commonFcm';

const Misc = withPrefetchedData(() => null, {
	prefetchName: __MODULE_HASH__,
	prefetchData: async (context) => {
		await Promise.all([prefetchCommonFcm(context)]);
	},
});

const prefetchDependencies: readonly PrefetchableComponentType[] = [Misc, DefaultLayout, Banner];

export function getPrefetchDependencies(): readonly PrefetchableComponentType[] {
	return prefetchDependencies;
}
