import { useEffect, useRef } from 'react';
import type { JSX } from 'react';

import type { LoadingBarRef } from 'react-top-loading-bar';
import LoadingBar from 'react-top-loading-bar';

import { useColor } from '@change-corgi/design-system/theme';

type Callbacks = { start: () => void; complete: () => void };
export type LoadingContext = {
	start: () => void;
	complete: () => void;
	listen: (callbacks: Callbacks) => () => void;
};

export function createLoadingContext(): LoadingContext {
	let callbacks: Callbacks[] = [];
	return {
		start: () => {
			callbacks.forEach(({ start }) => start());
		},
		complete: () => {
			callbacks.forEach(({ complete }) => complete());
		},
		listen: (cbs: Callbacks) => {
			callbacks = [...callbacks, cbs];
			return () => {
				callbacks = callbacks.filter((stateCbs) => stateCbs !== cbs);
			};
		},
	};
}

export function TopLoadingBar({ loadingContext }: { loadingContext?: LoadingContext }): JSX.Element {
	const ref = useRef<LoadingBarRef | null>(null);
	const color = useColor('primary-changeRed');

	useEffect(() => {
		if (!loadingContext) {
			return undefined;
		}
		const removeListener = loadingContext.listen({
			start: () => ref.current?.continuousStart(0, 1000),
			complete: () => ref.current?.complete(),
		});
		return () => {
			removeListener();
		};
	}, [loadingContext]);

	return <LoadingBar color={color} ref={ref} />;
}
