/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
import type { ExtendedLoadableComponent } from '@change-corgi/core/react/loadable';
import type { PrefetchableComponentType, PrefetchDependency } from '@change-corgi/core/react/prefetch';

function isLoadableComponent(component: PrefetchDependency): component is ExtendedLoadableComponent<any> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return !!(component as any).load;
}

export async function resolvePrefetchableComponent(component: PrefetchDependency): Promise<PrefetchableComponentType> {
	if (isLoadableComponent(component)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const cmpModule = (await component.load()) as any;
		const { exportName } = component;
		const moduleExport = exportName ? cmpModule[exportName] : cmpModule.default;
		if (!moduleExport) {
			throw new Error(`Cannot resolve component for prefetching (lookup: ${exportName || 'default'})`);
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return moduleExport as PrefetchableComponentType;
	}
	return component;
}
