import { useEffect, useMemo, useState } from 'react';
import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useLocale } from 'src/app/shared/hooks/l10n';

import { genScript } from './script';

type Props = Readonly<{
	allowed: boolean;
}>;

export function NewRelic({ allowed }: Props): JSX.Element | null {
	// initialize for first render for SSR support
	const [enabled, setEnabled] = useState(allowed);
	const locale = useLocale();

	useEffect(() => {
		// once it has been enabled once, leave it enabled
		setEnabled((alreadyEnabled) => alreadyEnabled || allowed);
	}, [setEnabled, allowed]);

	const script = useMemo(() => genScript({ locale }), [locale]);

	if (!enabled) return null;

	return (
		<Helmet>
			<script>{script}</script>
		</Helmet>
	);
}
