import type { JSX } from 'react';

import type { PrefetchedUserDataProps } from '@change-corgi/core/react/prefetch';
import { withPrefetchedData } from '@change-corgi/core/react/prefetch';

import { TermsOfServiceBanner } from '../components/TermsOfServiceBanner';
import { prefetchBanner, useBanner } from '../hooks/banner';

type PrefetchedUserData = Awaited<ReturnType<typeof prefetchBanner>>;

export const BannerSwitchContainer = withPrefetchedData(
	({ prefetchedUserData }: PrefetchedUserDataProps<PrefetchedUserData>): JSX.Element | null => {
		const banner = useBanner(prefetchedUserData);

		switch (banner?.type) {
			case 'tos':
				return (
					<TermsOfServiceBanner
						onDismiss={banner.dismiss}
						onView={banner.handleView}
						onLinkClick={banner.trackLinkClick}
						copy={banner.copy}
					/>
				);
			default:
				return null;
		}
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchUserData: async (context) => prefetchBanner(context),
	},
);
