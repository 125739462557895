/**
 * The goal of this module is to put together all app-wide contexts that can be prefetched and cached (e.g. fcm, signatures, ...)
 */

import { useMemo } from 'react';

import { createFcmCachePrefetchContext, useFcmCachePrefetchContext } from 'src/app/shared/hooks/fcm';
import { createShortUrlsCachePrefetchContext, useShortUrlsCachePrefetchContext } from 'src/app/shared/hooks/shortUrls';

export type AppCache = ReturnType<typeof useFcmCachePrefetchContext> &
	ReturnType<typeof useShortUrlsCachePrefetchContext>;
export type AppCacheObject = { [key in keyof AppCache]: ReturnType<AppCache[key]['getState']> };

/**
 * Should be used by any component that needs access to the app-wide contexts caches (e.g. a component doing prefetching)
 */
export function useCache(): AppCache {
	const fcm = useFcmCachePrefetchContext();
	const shortUrls = useShortUrlsCachePrefetchContext();

	return useMemo(
		() => ({
			...fcm,
			...shortUrls,
		}),
		[fcm, shortUrls],
	);
}

/**
 * Should be used by the server when creating the cache for prefetching
 */
export function createCache(): AppCache {
	return {
		...createFcmCachePrefetchContext(),
		...createShortUrlsCachePrefetchContext(),
	};
}

/**
 * Should be used by the server when extracting the cache data for serialization
 */
export function cacheToObject(cache: AppCache): AppCacheObject {
	return {
		fcm: cache.fcm.getState(),
		shortUrls: cache.shortUrls.getState(),
	};
}
