import newrelicConfig from 'config/newrelic.json';

import webappInfo from 'src/webappInfo';

// to update:
// go to NR > Browsers > Corgi (prod) > Application Settings
// and copy last 2 lines from snippet
import staticScript from './staticScript.txt';

// we can try enabling cookies later based on cookie prefs, if the data we're getting is not complete enough
const COOKIES_ENABLED = false;

// WARN: if copy/pasting the script for updates, make sure to
// 1. update NREUM.loader_config and NREUM.info to use newrelicConfig[window.location.hostname]
// 2. update NREUM.init to use COOKIES_ENABLED
// 3. update the regexs in the script to use double backslashes (\\)
export function genScript({ locale }: { locale: string }): string {
	const customAttributes: Record<string, string | number> = {
		appVersion: webappInfo.version,
		appVersionNormalized: webappInfo.versionNormalized,
		locale,
	};
	/* eslint-disable: prettier */
	return `(function () {
const newrelicConfig = ${JSON.stringify(newrelicConfig)};
if(newrelicConfig[window.location.hostname]){
	/* NEW RELIC BROWSER */
	;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:${COOKIES_ENABLED.toString()}},ajax:{deny_list:["bam.nr-data.net"]}};
	;NREUM.loader_config=newrelicConfig[window.location.hostname]
	;NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:newrelicConfig[window.location.hostname].licenseKey,applicationID:newrelicConfig[window.location.hostname].applicationID,sa:1}
	${staticScript}
	if (window.newrelic) {
		${Object.entries(customAttributes)
			.map(([name, value]) => `window.newrelic.setCustomAttribute('${name}', ${JSON.stringify(value)});`)
			.join('\n\t\t')}
	}
}
})();`;
	/* eslint-enable: prettier */
}
