import { useCookiePrefsSavedAsync } from 'src/app/shared/hooks/session';

import { useGdprEnabled } from './gdprEnabled';
import { useCurrentLocationIgnoredByCookieWall } from './ignoredLocation';
import { useLocalStorageCookiePrefs } from './localCookiePrefs';

export function useCookieWallDisplayed(): boolean {
	const isGDPR = useGdprEnabled();
	const localCookiePrefs = useLocalStorageCookiePrefs();
	const cookiePrefsSaved = useCookiePrefsSavedAsync();
	const ignoredLocation = useCurrentLocationIgnoredByCookieWall();

	// we want to disable showing if serverside or cookie prefs are in localStorage
	if (ignoredLocation || !isGDPR || localCookiePrefs !== null) return false;

	return cookiePrefsSaved.status === 'loaded' && !cookiePrefsSaved.value;
}
