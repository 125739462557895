import { useEffect } from 'react';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';
import { googleTagManagerTracking } from 'src/app/shared/utils/googleTagManagerTracking';

export function useNotifyProfitWell(): void {
	const sessionState = useSessionAsync();
	const session = isLoaded(sessionState) ? sessionState.value : undefined;
	useEffect(() => {
		// pushing user data to tag manager if available
		// ProfitWell (3rd party service for subscription management) requires user data to be pushed to GTM
		if (!session) return;
		if (session.user) {
			googleTagManagerTracking.pushUserId(Number.parseInt(session.user.id, 10));
			googleTagManagerTracking.pushUserEmail(session.user.email);
		}
		googleTagManagerTracking.pushUserDataAvailable();
	}, [session]);
}
