import { FCM_TOS_LEGAL_NOTICE_BANNER_COPY } from '@change-corgi/config/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm: useCopyFcm, getFcm: getCopyFcm } = createFcmFunctions(
	{
		tosCopy: FCM_TOS_LEGAL_NOTICE_BANNER_COPY,
	},
	{ hook: true, getter: true },
);
