// should be first
import './public-path';
// core-js is only necessary for building client bundle, so it can be in devDependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js';
import 'regenerator-runtime/runtime';

import { createClientApp } from './createClientApp';
import { createClientErrorApp } from './createClientErrorApp';
import { init } from './init';

// FIXME: temporary
// this is for the sake of investigating reported errors where some of the signature data is unexpectedly missing
// https://changeorg.airbrake.io/locate/0005f829-acb7-76ad-5478-965c88824388
// see also src/app/pages/petition/details/containers/signForm/SignForm.tsx
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as any).INITIAL_HREF = window.location.href;

void init({
	createApp: (bootstrapData) =>
		bootstrapData.error ? createClientErrorApp(bootstrapData) : createClientApp(bootstrapData),
});
